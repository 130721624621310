import { useState } from "react";

const DropdownList = ({ items, htmlAnswer, htmlQuestion }) => {
  const [ activeIndex, setActiveIndex ] = useState(null);

  const handleClick = (index) => {
    const element = document.getElementById(`faq-item-${index}`);
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
      setTimeout(() => {
        element.scrollIntoView({
          inline: "nearest",
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  };
  // ul space-y-4 should work but is not
  return (
    <ul className="">
      {items.map((item, index) => (
        <li
          key={index}
          id={`faq-item-${index}`}
          className="flex flex-col justify-center pt-2 pb-1 divider-dots"
        >
          <button
            onClick={() => handleClick(index)}
            className={`hover:pl-1 transition-all text-left cursor-pointer select-none font-georgia italic trans-ease ${
              activeIndex === index
                ? "text-[#008ae1b3] animate"
                : "hover:text-[#008ae1b3]"
            }`}
          >
            <h2>{item.question}</h2>
          </button>
          <div
            className={`overflow-hidden transition-all  ${
              activeIndex === index
                ? "opacity-100 trans-ease-slow mt-1"
                : "max-h-0 opacity-0 trans-ease-fast"
            }`}
          >
            <div className="panel-box faq-answers">
              {!htmlAnswer && <p>{item.answer}</p>}
              {htmlAnswer && (
                <div
                  className="mb-1 animate-in spin-in"
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default DropdownList;
